.a {
  height: 100vh;
  display: flex;
  align-items: center;
}

.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.a-right {
  flex: 1;
  margin-right: 20px;
}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: black;
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a-stack-img-wrapper {
  width: 50%;
  height: 70px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
}

.a-stack-img {
  width: 60px;
  height: 60px;
  border-radius: 20px;
}

.a-title {
  font-weight: 400;
}
.a-sub {
  margin: 20px 0px;
}

.a-desc {
  font-weight: 300;
}

.a-stack {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.a-stack-texts {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.a-stack-title {
  font-weight: bold;
  margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
  .a {
    height: 135vh;
    flex-direction: column;
    text-align: center;
    margin-top: 10px;
  }

  .a-left {
    flex: 0.4;
    width: 100%;
  }

  .a-card {
    height: 30vh;
  }

  .a-card.bg {
    display: none;
  }

  .a-right {
    flex: 0.7;
    padding: 20px;
  }

  .a-stack-img-wrapper {
    width: 70%;
    justify-content: space-between;
  }
}
